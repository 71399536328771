import Vue from 'vue'
import Vuex from 'vuex'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

function getEnv() {
    const { host } = window.location
    if(host.includes('westbic.ie')) return 'prod'
    if(host.includes('localhost')) return 'dev'
    return 'dev'
}

const ENV = getEnv()

firebase.initializeApp(ENV === 'prod' ? {
    apiKey: "AIzaSyAkOjsVrLeLUnYyPteKbQhV5L4mZQFXbNo",
    authDomain: "westbic-53521.firebaseapp.com",
    databaseURL: "https://westbic-53521.firebaseio.com",
    projectId: "westbic-53521",
    storageBucket: "westbic-53521.appspot.com",
    messagingSenderId: "1077666382632"
} : {
    apiKey: "AIzaSyDbj0RKDmZttXTb4Yx30mV4pZNHS-d-RB4",
    authDomain: "bps-staging-4.firebaseapp.com",
    databaseURL: "https://bps-staging-4.firebaseio.com",
    projectId: "bps-staging-4",
    storageBucket: "bps-staging-4.appspot.com",
    messagingSenderId: "852602455701"
})

firebase.firestore().settings({ timestampsInSnapshots: true })

Vue.use(Vuex)

const getLocalPosts = () => {
    if(window.localStorage) {
        const posts = window.localStorage.getItem('posts')
        if(posts) {
            try {
                return JSON.parse(posts)
            }
            catch(e) {
                return []
            }
        }
    }
    return []
}

export const store = new Vuex.Store({
    state: {
        firebase,
        posts: getLocalPosts(),
        settings: {},
        isMobile: window.innerWidth < 1024,
        testMode: ENV === 'dev',
        admin: null,
        screenWidth: window.innerWidth,
    },
    getters: {
        getPosts(state) {
            return state.posts
        },
        getSettings(state) {
            return state.settings
        },
        getFunctions(state) {
            return state.firebase.functions()
        },
        getMobile(state) {
            return state.isMobile
        },
        getTest(state) {
            return state.testMode
        },
        getAdmin(state) {
            return state.admin
        },
        getEnv() {
            return ENV
        },
        getScreenWidth(state) {
            return state.screenWidth
        },
    },
    mutations: {
        setPosts(state, posts) {
            state.posts = posts || []
            if(window.localStorage) window.localStorage.setItem('posts', JSON.stringify(state.posts))
        },
        setSettings(state, settings) {
            state.settings = settings || {}
            // eslint-disable-next-line no-console
            console.log(settings);
        },
        setMobile(state, value) {
            state.isMobile = value
        },
        setTestMode(state, value) {
            state.testMode = value
            firebase.functions().httpsCallable('getPosts')({ test: value }).then(res => store.commit('setPosts', res.data))
        },
        setAdmin(state, admin) {
            state.admin = admin
        },
        setScreenWidth(state, width) {
            state.screenWidth = width
        },
    }
})

window.addEventListener('resize', () => {
    store.commit('setScreenWidth', window.innerWidth)
})

let userRef, settingsRef

firebase.auth().onAuthStateChanged(user => {
    if(userRef) userRef()
    if(settingsRef) settingsRef()
    if(user) {
        userRef = firebase.firestore().collection('admins').doc(user.uid).onSnapshot(data => {
            if(data.exists) {
                const testMode = ENV === 'dev' || (ENV === 'staging' && data.get('test_mode') === true)
                settingsRef = firebase.firestore().collection(`${testMode ? 'test_' : ''}settings`).doc('settings').onSnapshot(settings => {
                    store.commit('setSettings', settings.exists ? settings.data() : {})
                    // eslint-disable-next-line no-console
                    console.log(settings.data());
                })
                store.commit('setTestMode', testMode)
                store.commit('setAdmin', data.data())
            }
            else firebase.auth().signOut()
        })
    }
    else {
        settingsRef = firebase.firestore().collection(`${ENV === 'dev' ? 'test_' : ''}settings`).doc('settings').onSnapshot(settings => {
            store.commit('setSettings', settings.exists ? settings.data() : {})
        })
        store.commit('setTestMode', ENV === 'dev')
        store.commit('setAdmin', null)
    }
})

window.addEventListener('resize', () => store.commit('setMobile', window.innerWidth < 1024))