<template>
  <span class="bps-toggle-button-container">
    <button class="bps-toggle-button" :toggled="value" @click="toggle">
      <span class="thumb"></span>
    </button>
  </span>
</template>

<script>
export default {
  name: 'BpsToggleButton',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped>
  button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #D2D2D2;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    border: none;
    transition: background .16s ease-out;
  }

  button[toggled] {
    background: #343434;
  }

  .bps-toggle-button span {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 14px;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
    transition: transform .16s ease-out;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    will-change: transform;
  }

  button[toggled] span {
    transform: translateX(16px);
  }
</style>
